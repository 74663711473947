import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  AuthUser,
  Consultation,
  ConsultationStatusEnum,
  ContentChangeRequest,
  Contract,
  CustomRequestTypeEnum,
  ExpertStepDto,
  GetManyConsultationsResponseDto,
  GetManyCustomRequestResponseDto,
  NotificationsSetting,
  Payment,
  PaymentMethod,
  Portfolio,
  Promocode,
  User,
  UserCategoryMeta,
  UserPromocode,
  UserStepsEnum,
  VerificationRequest,
  Wallet,
} from 'shared/api';
import { COOKIE_KEY } from 'shared/common/constants';
import { LoadingStatus, Steps } from 'shared/common/types';
import { removeCookie } from 'shared/helpers';

import {
  addPortfolioImageUrlsAsync,
  addUserStepAsync,
  applyPromocodeAsync,
  createContentChangeRequest,
  createOneCustomRequestAsync,
  deleteRefreshZoomTokenAsync,
  fetchAddUserToCategory,
  fetchConsultations,
  fetchConsultationsWithoutReview,
  fetchContentChangeRequests,
  fetchDeletePortfoliosOneImageRequests,
  fetchGetContractInfoRequest,
  fetchGetExpertPromocodesRequest,
  fetchGetManyBaseCustomRequest,
  fetchMe,
  fetchSelfContentChangeRequests,
  fetchSelfContract,
  fetchSelfPromocode,
  fetchSelfVerificationRequests,
  fetchUpdateIam,
  fetchUpdateIamProfile,
  fetchWallet,
  getManyReviewsAsync,
  removeConsultationWithReview,
  sendSmsAsync,
  sendVerificationEmailAsync,
  sendZoomCodeAsync,
  setToken,
  signOut,
  updateOneCustomRequestAsync,
  updateUserSetExpert,
} from './actions';

interface PaginatedPayments {
  data: Payment[];
  count: number;
  page: number;
  total: number;
  pageCount: number | null;
}

interface GetManyConsultationResponseDto {
  data: Consultation[];
  count: number;
  page: number;
  total: number;
  pageCount: number | null;
}

export interface ProfileState {
  token: string | null;
  user: User;
  notificationsSetting: NotificationsSetting;
  promocodes: UserPromocode[];
  contentChangeRequests: ContentChangeRequest[];
  verificationRequests: VerificationRequest[];
  wallet: Wallet;
  payments: PaginatedPayments;
  paymentMethods: PaymentMethod[];
  consultationsWithoutReview: Consultation[];
  contract: Contract;
  expertPromocode: Promocode;
  lastContentChangeRequest: ContentChangeRequest;
  payedConsultations: GetManyConsultationResponseDto;
  getManyBaseCustomRequest: GetManyCustomRequestResponseDto;
  getContractInfoRequest: Contract;
  getExpertPromocodesRequest: Promocode[];
  lastStep: Steps | undefined;
  consultations: GetManyConsultationsResponseDto;
  isShowHelloBar: boolean;
  updatePortfolioImagesLoading: LoadingStatus;
  error: string | null | undefined | SerializedError;
}

const initialState: ProfileState = {
  token: '',
  user: {} as User,
  notificationsSetting: {} as NotificationsSetting,
  promocodes: [],
  contentChangeRequests: [],
  wallet: {} as Wallet,
  verificationRequests: [],
  payments: {} as PaginatedPayments,
  paymentMethods: [],
  expertPromocode: {} as Promocode,
  lastContentChangeRequest: {} as ContentChangeRequest,
  consultationsWithoutReview: [],
  contract: {} as Contract,
  payedConsultations: {} as GetManyConsultationResponseDto,
  getManyBaseCustomRequest: {} as GetManyCustomRequestResponseDto,
  getContractInfoRequest: {} as Contract,
  getExpertPromocodesRequest: [],
  lastStep: undefined,
  consultations: {} as GetManyConsultationsResponseDto,
  isShowHelloBar: true,
  updatePortfolioImagesLoading: 'idle',
  error: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetStore: () => initialState,
    authUser: {
      reducer: (state, action: PayloadAction<AuthUser>) => {
        state.token = action.payload.token;
        state.user = action.payload.user;
      },
      prepare: (data: AuthUser) => ({
        payload: data,
      }),
    },
    updateUser: {
      reducer: (state, action: PayloadAction<Partial<User>>) => {
        state.user = { ...state.user, ...action.payload };
      },
      prepare: (user: Partial<User>) => ({
        payload: user,
      }),
    },
    setContentChangeRequests: {
      reducer: (state, action: PayloadAction<ContentChangeRequest[]>) => {
        state.contentChangeRequests = action.payload;
        const lastRequest = action.payload[0];
        state.lastContentChangeRequest = lastRequest;
      },
      prepare: (contentChangeRequests: ContentChangeRequest[]) => ({
        payload: contentChangeRequests,
      }),
    },

    setGetManyBaseCustomRequest: {
      reducer: (state, { payload }: PayloadAction<GetManyCustomRequestResponseDto>) => {
        state.getManyBaseCustomRequest = payload;
        if (payload.data.length) {
          const lastPromocode = payload.data.reduce((a, b) => {
            if (b.type !== CustomRequestTypeEnum.PersonalPromocode) {
              return a;
            }
            return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
          }, payload.data[0]).promocode;

          state.expertPromocode = lastPromocode || state.expertPromocode;
        }
      },
      prepare: (requests: GetManyCustomRequestResponseDto) => ({
        payload: requests,
      }),
    },

    setGetContractInfoRequest: {
      reducer: (state, { payload }: PayloadAction<Contract>) => {
        state.getContractInfoRequest = payload;
      },
      prepare: (requests: Contract) => ({
        payload: requests,
      }),
    },

    setGetExpertPromocodesRequest: {
      reducer: (state, { payload }: PayloadAction<Promocode[]>) => {
        state.getExpertPromocodesRequest = payload;
      },
      prepare: (requests: Promocode[]) => ({
        payload: requests,
      }),
    },

    setWallet: {
      reducer: (state, { payload }: PayloadAction<Wallet>) => {
        state.wallet = payload;
      },
      prepare: (wallet: Wallet) => ({
        payload: wallet,
      }),
    },

    updatePaymentMethods: {
      reducer: (state, { payload }: PayloadAction<PaymentMethod[]>) => {
        state.paymentMethods = payload;
      },
      prepare: (paymentMethods: PaymentMethod[]) => ({
        payload: paymentMethods,
      }),
    },

    updateUserCategories: {
      reducer: (state, action: PayloadAction<UserCategoryMeta[]>) => {
        if (state.user.categories) {
          state.user.categories.push(...action.payload);
        } else {
          state.user.categories = action.payload;
        }
      },
      prepare: (category: UserCategoryMeta[]) => ({
        payload: category,
      }),
    },
    deleteUserCategory: {
      reducer: (state, action: PayloadAction<number>) => {
        state.user.categories = state.user.categories?.filter((c) => c.id !== action.payload) || [];
      },
      prepare: (id: number) => ({
        payload: id,
      }),
    },

    addStep: (state, { payload }: PayloadAction<ExpertStepDto>) => {
      const steps = [...state.user.steps, payload.step as unknown as UserStepsEnum];
      state.user.steps = _.uniq(steps);
    },
    updateNotificationSetting: {
      reducer: (state, action: PayloadAction<NotificationsSetting>) => {
        state.notificationsSetting = action.payload;
      },
      prepare: (setting: NotificationsSetting) => ({
        payload: setting,
      }),
    },

    addUserPromocodes: {
      reducer: (state, action: PayloadAction<UserPromocode[]>) => {
        state.promocodes = action.payload;
      },
      prepare: (promocodes: UserPromocode[]) => ({
        payload: promocodes,
      }),
    },

    setVerificationRequests: {
      reducer: (state, { payload }: PayloadAction<VerificationRequest[]>) => {
        state.verificationRequests = payload;
      },
      prepare: (requests: VerificationRequest[]) => ({
        payload: requests,
      }),
    },

    addUserPayments: {
      reducer: (state, action: PayloadAction<PaginatedPayments>) => {
        state.payments = action.payload;
      },
      prepare: (payments: PaginatedPayments) => ({
        payload: payments,
      }),
    },

    setIsShowHelloBar: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isShowHelloBar = action.payload;
      },
      prepare: (data: boolean) => ({
        payload: data,
      }),
    },

    addUserConsultations: {
      reducer: (state, action: PayloadAction<GetManyConsultationResponseDto>) => {
        state.payedConsultations = action.payload;
      },
      prepare: (consultations: GetManyConsultationResponseDto) => ({
        payload: consultations,
      }),
    },

    setUserContract: {
      reducer: (state, action: PayloadAction<Contract>) => {
        state.contract = action.payload;
      },
      prepare: (contract: Contract) => ({
        payload: contract,
      }),
    },
    setLastStep: (state, action: PayloadAction<Steps> | undefined) => {
      state.lastStep = action?.payload;
    },
  },

  extraReducers: {
    [setToken.type]: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    [fetchMe.fulfilled.type]: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    [signOut.fulfilled.type]: (state) => {
      state.user = {} as User;
      state.token = '';
      state.wallet = {} as Wallet;
      state.contract = {} as Contract;
      state = initialState;
      removeCookie(COOKIE_KEY.accessToken);
    },
    [fetchConsultationsWithoutReview.fulfilled.type]: (state, { payload }: PayloadAction<Consultation[]>) => {
      state.consultationsWithoutReview = payload.filter((c) =>
        [
          ConsultationStatusEnum.Ended,
          ConsultationStatusEnum.EndedByClient,
          ConsultationStatusEnum.EndedByExpert,
          ConsultationStatusEnum.MessagesLimitExceeded,
          ConsultationStatusEnum.TimeLimitExceeded,
        ].includes(c.status),
      );
    },
    [removeConsultationWithReview.type]: (state, { payload }: PayloadAction<number>) => {
      const consultationWithReviewIndex = state.consultationsWithoutReview.findIndex((cons) => cons.id === payload);
      state.consultationsWithoutReview.splice(consultationWithReviewIndex, 1);
    },

    [fetchContentChangeRequests.fulfilled.type]: (state, { payload }: PayloadAction<ContentChangeRequest[]>) => {
      // const lastRequest = payload.reduce((a, b) => (a.updatedAt > b.updatedAt ? a : b));
      const lastRequest = payload?.length ? payload[0] : state.lastContentChangeRequest;
      state.lastContentChangeRequest = lastRequest;
    },

    [fetchConsultations.fulfilled.type]: (state, { payload }: PayloadAction<GetManyConsultationsResponseDto>) => {
      state.consultations = payload;
    },

    [addPortfolioImageUrlsAsync.pending.type]: (state) => {
      state.updatePortfolioImagesLoading = 'pending';
    },
    [addPortfolioImageUrlsAsync.fulfilled.type]: (state, { payload }: PayloadAction<Portfolio>) => {
      state.user = { ...state.user, portfolio: { ...state.user.portfolio, ...payload } };
      state.updatePortfolioImagesLoading = 'idle';
    },

    [addPortfolioImageUrlsAsync.rejected.type]: (state, { payload }) => {
      state.updatePortfolioImagesLoading = 'idle';
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, code: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    [fetchDeletePortfoliosOneImageRequests.pending.type]: (state) => {
      state.updatePortfolioImagesLoading = 'pending';
    },
    [fetchDeletePortfoliosOneImageRequests.fulfilled.type]: (state, { payload }: PayloadAction<Portfolio>) => {
      state.user = { ...state.user, portfolio: { ...state.user.portfolio, ...payload } };
      state.updatePortfolioImagesLoading = 'idle';
    },

    [fetchDeletePortfoliosOneImageRequests.rejected.type]: (state, { payload }) => {
      state.updatePortfolioImagesLoading = 'idle';
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, code: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    [fetchAddUserToCategory.fulfilled.type]: (state, { payload }: PayloadAction<UserCategoryMeta>) => {
      if (state.user.categories) {
        state.user.categories.push(payload);
      } else {
        state.user.categories = [payload];
      }
    },

    [addUserStepAsync.fulfilled.type]: (state, { payload }: PayloadAction<UserStepsEnum>) => {
      if (state.user.steps) {
        state.user.steps.push(payload);
      } else {
        state.user.steps = [payload];
      }
    },
  },
});

export const { reducer } = profileSlice;
export const actions = {
  ...profileSlice.actions,
  fetchMe,
  signOut,
  setToken,
  fetchSelfPromocode,
  fetchUpdateIam,
  fetchConsultationsWithoutReview,
  removeConsultationWithReview,
  fetchConsultations,
  fetchWallet,
  fetchSelfContract,
  updateUserSetExpert,
  fetchContentChangeRequests,
  fetchUpdateIamProfile,
  sendSmsAsync,
  sendZoomCodeAsync,
  deleteRefreshZoomTokenAsync,
  applyPromocodeAsync,
  addPortfolioImageUrlsAsync,
  fetchDeletePortfoliosOneImageRequests,
  sendVerificationEmailAsync,
  createOneCustomRequestAsync,
  fetchGetManyBaseCustomRequest,
  updateOneCustomRequestAsync,
  getManyReviewsAsync,
  fetchAddUserToCategory,
  addUserStepAsync,
  createContentChangeRequest,
  fetchGetContractInfoRequest,
  fetchGetExpertPromocodesRequest,
  fetchSelfContentChangeRequests,
  fetchSelfVerificationRequests,
};
