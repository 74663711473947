/* eslint-disable no-prototype-builtins */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ConsultationOffer, ConsultationRequest, UserCategoryMeta } from 'shared/api';

import {
  addNewClientRequest,
  addNewOfferToRequest,
  clearClientRequests,
  createConsultationRequest,
  createOffer,
  fetchManyClientsRequests,
  fetchManyConsultationsRequests,
  resetRequestUnreadCount,
  resetRequestUnreadCountLocal,
  resetResponseUnreadCount,
  resetResponseUnreadCountLocal,
  resetState,
  setActiveRequestMessageId,
  setCurrentRequestRoom,
  setCurrentResponseRoom,
} from './actions';

export interface ClientRequestType extends UserCategoryMeta {
  requests: ConsultationRequest[];
}

const initialState = {
  userConsultationRequests: [],
  responseUnreadCount: {},
  currentResponseRoom: null,
  clientRequests: [],
  requestUnreadCount: {},
  currentRequestRoom: null,
  activeRequestMessageId: null,
} as {
  // responses tab
  userConsultationRequests: ConsultationRequest[];
  responseUnreadCount: Record<string, number>;
  currentResponseRoom: number | null;
  // requests tab
  clientRequests: ClientRequestType[];
  requestUnreadCount: Record<string, number>;
  currentRequestRoom: number | null;
  activeRequestMessageId: number | null;
};

const consultationRequestSlice = createSlice({
  name: 'consultationRequests',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    // responses tab
    [createConsultationRequest.fulfilled.type]: (state, { payload }: PayloadAction<ConsultationRequest>) => {
      state.userConsultationRequests.unshift(payload);
    },

    [fetchManyConsultationsRequests.fulfilled.type]: (state, { payload }: PayloadAction<ConsultationRequest[]>) => {
      const unreadResponsesMap: Record<string, number> = {};
      const withSortedOffers = [...payload].map((request) => {
        if (request.offers) {
          if (request.status === 'active') {
            request.offers.forEach((offer) => {
              if (dayjs(offer.createdAt).diff(dayjs(request.lastReadTime || 0)) > 0) {
                unreadResponsesMap.hasOwnProperty(request.id.toString())
                  ? unreadResponsesMap[request.id.toString()]++
                  : (unreadResponsesMap[request.id.toString()] = 1);
              }
            });
          }

          if (request.offers.length > 1) {
            const sortedOffers = [...request.offers].sort((a, b) => {
              if (dayjs(a.createdAt).diff(dayjs(b.createdAt)) === 0) return 0;
              if (dayjs(a.createdAt).diff(dayjs(b.createdAt)) > 0) return -1;
              return 1;
            });
            return { ...request, offers: sortedOffers };
          }
        }
        return { ...request };
      });
      const sortedPayload = withSortedOffers.sort((a, b) => {
        if (
          dayjs((a.offers && a.offers[0]?.createdAt) || a.createdAt).diff(
            dayjs((b.offers && b.offers[0]?.createdAt) || b.createdAt),
          ) > 0
        ) {
          return -1;
        }
        if (
          dayjs((a.offers && a.offers[0]?.createdAt) || a.createdAt).diff(
            dayjs((b.offers && b.offers[0]?.createdAt) || b.createdAt),
          ) === 0
        ) {
          return 0;
        }
        return 1;
      });
      state.userConsultationRequests = sortedPayload;
      state.responseUnreadCount = unreadResponsesMap;
    },

    [setCurrentResponseRoom.type]: (state, { payload }: PayloadAction<{ roomId: number }>) => {
      state.currentResponseRoom = payload.roomId;
    },

    [addNewOfferToRequest.type]: (state, { payload }: PayloadAction<ConsultationOffer>) => {
      const requestIndex = state.userConsultationRequests.findIndex(
        (request) => request.id === payload.consultationRequestId,
      );
      if (requestIndex !== -1) {
        const withNewOffer = { ...state.userConsultationRequests[requestIndex] };
        if (withNewOffer.hasOwnProperty('offers')) {
          withNewOffer.offers!.unshift(payload);
        } else {
          withNewOffer.offers = [payload];
        }
        state.userConsultationRequests.splice(requestIndex, 1);
        state.userConsultationRequests.unshift(withNewOffer);

        if (state.currentResponseRoom !== payload.consultationRequestId) {
          if (state.responseUnreadCount.hasOwnProperty(payload.consultationRequestId)) {
            state.responseUnreadCount[payload.consultationRequestId]++;
          } else {
            state.responseUnreadCount = {
              ...state.responseUnreadCount,
              [payload.consultationRequestId]: 1,
            };
          }
        }
      }
    },

    [resetResponseUnreadCountLocal.type]: (state, { payload }: PayloadAction<{ requestId: number }>) => {
      if (state.responseUnreadCount.hasOwnProperty(payload.requestId)) {
        delete state.responseUnreadCount[payload.requestId];
      }
    },

    // requests tab
    [addNewClientRequest.type]: (state, { payload }: PayloadAction<ConsultationRequest>) => {
      const clientRequestsIndex = state.clientRequests.findIndex((group) => group.categoryId === payload.categoryId);
      if (clientRequestsIndex !== -1) {
        const withNewRequest = { ...state.clientRequests[clientRequestsIndex] };
        withNewRequest.requests.unshift(payload);
        state.clientRequests.splice(clientRequestsIndex, 1);
        state.clientRequests.unshift(withNewRequest);

        if (payload.categoryId !== state.currentRequestRoom) {
          state.requestUnreadCount.hasOwnProperty(payload.categoryId.toString())
            ? state.requestUnreadCount[payload.categoryId.toString()]++
            : (state.requestUnreadCount[payload.categoryId.toString()] = 1);
        }
      }
    },

    [fetchManyClientsRequests.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ categories: UserCategoryMeta[]; requests: ConsultationRequest[] }>,
    ) => {
      const groupByCategoryMap = {} as { [key: number]: ClientRequestType };
      const unreadRequestsMap = {} as Record<string, number>;
      payload.categories.forEach((category) => {
        groupByCategoryMap[category.categoryId] = { ...category, requests: [] };
      });

      payload.requests.forEach((request) => {
        if (groupByCategoryMap.hasOwnProperty(request.categoryId) && Boolean(request.client)) {
          groupByCategoryMap[request.categoryId].requests.push(request);

          if (dayjs(request.createdAt).diff(dayjs(groupByCategoryMap[request.categoryId].lastReadTime || 0)) > 0) {
            unreadRequestsMap.hasOwnProperty(request.categoryId.toString())
              ? unreadRequestsMap[request.categoryId.toString()]++
              : (unreadRequestsMap[request.categoryId.toString()] = 1);
          }
        }
      });

      Object.keys(groupByCategoryMap).forEach((groupKey) => {
        groupByCategoryMap[Number(groupKey)].requests.sort((a, b) =>
          dayjs(a.createdAt).diff(dayjs(b.createdAt)) > 0
            ? -1
            : dayjs(a.createdAt).diff(dayjs(b.createdAt)) < 0
            ? 1
            : 0,
        );
      });

      const sortedRequestsGroups = Object.values(groupByCategoryMap).sort((a, b) => {
        if (!a.requests.length) {
          return 1;
        }
        if (!b.requests.length) {
          return -1;
        }
        return dayjs(a.requests[0].createdAt).diff(dayjs(b.requests[0].createdAt)) > 0 ? -1 : 1;
      });

      state.clientRequests = sortedRequestsGroups;
      state.requestUnreadCount = unreadRequestsMap;
    },
    [fetchManyClientsRequests.rejected.type]: (state) => {
      state.clientRequests = [];
      state.requestUnreadCount = {};
    },
    [clearClientRequests.type]: (state) => {
      state.clientRequests = [];
      state.requestUnreadCount = {};
    },

    [setCurrentRequestRoom.type]: (state, { payload }: PayloadAction<{ roomId: number }>) => {
      state.currentRequestRoom = payload.roomId;
    },

    [setActiveRequestMessageId.type]: (state, { payload }: PayloadAction<{ requestId: number | null }>) => {
      state.activeRequestMessageId = payload.requestId;
    },

    [resetRequestUnreadCountLocal.type]: (state, { payload }: PayloadAction<{ categoryId: number }>) => {
      if (state.requestUnreadCount.hasOwnProperty(payload.categoryId)) {
        delete state.requestUnreadCount[payload.categoryId];
      }
    },
    [resetState.type]: () => initialState,
  },
});

export const { reducer } = consultationRequestSlice;
export const actions = {
  createConsultationRequest,
  fetchManyConsultationsRequests,
  addNewClientRequest,
  fetchManyClientsRequests,
  setCurrentRequestRoom,
  setCurrentResponseRoom,
  setActiveRequestMessageId,
  createOffer,
  addNewOfferToRequest,
  resetResponseUnreadCount,
  resetResponseUnreadCountLocal,
  resetRequestUnreadCount,
  resetRequestUnreadCountLocal,
  clearClientRequests,
  resetState,
};
