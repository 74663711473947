import Pusher from 'pusher-js';

Pusher.log = function (message) {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'development') {
    if (window.console && window.console.log) {
      window.console.log(message);
    }
  }
};

class PusherService {
  pusher?: Pusher | null;

  init(accessToken: string | null): Pusher | null {
    if (this.pusher) {
      return this.pusher;
    }
    if (accessToken) {
      this.pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY || '', {
        cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
        forceTLS: false,
        // authEndpoint: '/api/pusher',
        authEndpoint: process.env.NEXT_PUBLIC_PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });
      return this.pusher;
    }
    this.pusher = null;
    return this.pusher;
  }
}

export default new PusherService();
