import { createAction, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  AddImageUrlsDto,
  api,
  ApplyPromocodeDto,
  Consultation,
  ContentChangeRequest,
  Contract,
  CreateCustomRequestDto,
  CreateIndividualContractDto,
  CreateSelfEmployedContractDto,
  CreateUserCategoryMetaDto,
  CreateVerificationRequestDto,
  CustomRequest,
  ExpertStepDto,
  ExpertZoomCodeDto,
  GetManyConsultationsResponseDto,
  GetManyCustomRequestResponseDto,
  GetManyReviewResponseDto,
  IndexDto,
  NotificationsSetting,
  PaymentOperationTypeEnum,
  Portfolio,
  Promocode,
  SigninDto,
  SmsCodeDto,
  UpdateCustomRequestDto,
  UpdateDefaultPaymentMethodDto,
  UpdateNotificationsSettingDto,
  UpdateSelfEmployedContractDto,
  UpdateUserCategoryMetaDto,
  UpdateUserDto,
  UpdateUserProfileDto,
  User,
  UserCategoryMeta,
  UserStepsEnum,
  VerificationRequest,
} from 'shared/api';
import { COOKIE_KEY } from 'shared/common/constants';
import { ExtraParamsThunkType, Params, Services } from 'shared/common/types';
import { handleAsyncError, setCookie } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';

import { actions as rootActions } from '../../ducks';
import { RootState } from '../../index';
import * as selectors from './selectors';
import { selectVerificationRequest } from './selectors';
import { actions } from './slice';

export const setToken = createAction<string>('user/setToken');

export const fetchMe = createAsyncThunk<User, undefined, ExtraParamsThunkType<SerializedError>>(
  'user/fetch-me',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data: user } = await api.V1UsersApi.usersControllerGetMe();
      return user;
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
      return rejectWithValue(error);
    }
  },
);

export const fetchSelfVerificationRequests = createAsyncThunk<
  VerificationRequest[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('profile/fetchSelfVerificationRequests', async (payload, { extra: { api }, rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.V1VerificationRequestsApi.verificationRequestsControllerGetUserRequests();

    dispatch(actions.setVerificationRequests(data));
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const fetchGetManyBaseCustomRequest = createAsyncThunk<
  GetManyCustomRequestResponseDto,
  Params | undefined,
  ExtraParamsThunkType<SerializedError>
>('profile/fetchGetManyBaseCustomRequest', async (params, { extra: { api }, dispatch, rejectWithValue }) => {
  try {
    const { data } = await api.V1CustomRequestsApi.getManyBaseCustomRequestsControllerCustomRequest(
      params?.fields,
      params?.s,
      params?.filter,
      params?.or,
      params?.sort,
      params?.join,
      params?.limit,
      params?.offset,
      params?.page,
      params?.cache,
      params?.options,
    );

    dispatch(actions.setGetManyBaseCustomRequest(data));
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const fetchGetContractInfoRequest = createAsyncThunk<Contract, undefined, ExtraParamsThunkType<SerializedError>>(
  'profile/fetchGetContractInfoRequest',
  async (payload, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.V1ContractsApi.contractsControllerGetSelf();

      dispatch(actions.setGetContractInfoRequest(data));
      return data;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
      return rejectWithValue(e);
    }
  },
);

export const fetchGetExpertPromocodesRequest = createAsyncThunk<
  Promocode[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('profile/fetchGetExpertPromocodesRequest', async (payload, { extra: { api }, rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.V1PromocodesApi.promocodesControllerGetExpertPromocodes();

    dispatch(actions.setGetExpertPromocodesRequest(data));
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const sendVerificationRequest = createAsyncThunk<
  void,
  CreateVerificationRequestDto,
  { state: RootState; rejectValue: SerializedError; extra: { api: Services } }
>('profile/sendVerificationRequest', async (payload, { dispatch, getState }) => {
  try {
    const verificationRequest = selectVerificationRequest(getState());
    if (verificationRequest) {
      await api.V1VerificationRequestsApi.verificationRequestsControllerUpdateOne(verificationRequest.id, payload);
    } else {
      await api.V1VerificationRequestsApi.verificationRequestsControllerCreateOne(payload);
    }

    dispatch(fetchSelfContentChangeRequests());
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
  }
});

export const fetchSelfContentChangeRequests = createAsyncThunk<
  ContentChangeRequest[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('profile/fetchSelfContentChangeRequests', async (_, { dispatch, rejectWithValue }) => {
  try {
    const { data } =
      await api.V1ContentChangeRequestApi.getManyBaseContentChangeRequestsControllerContentChangeRequest();

    dispatch(actions.setContentChangeRequests(data));
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const fetchDeletePortfoliosOneImageRequests = createAsyncThunk<
  Portfolio,
  { id: number; indexDto: IndexDto },
  ExtraParamsThunkType<SerializedError>
>('profile/fetchDeletePortfoliosOneImageRequests', async ({ id, indexDto }, { rejectWithValue }) => {
  try {
    const { data } = await api.V1PortfoliosApi.portfoliosControllerDeleteOneImageUrl(id, indexDto);
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const fetchWallet = createAsyncThunk('profile/fetchWallet', async (payload, { dispatch }) => {
  try {
    const { data } = await api.V1WalletsApi.walletsControllerGetSelfWallet();

    dispatch(actions.setWallet(data));
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
  }
});

export const fetchSelfPayments = createAsyncThunk<void, { page: number; type?: PaymentOperationTypeEnum }>(
  'profile/fetchSelfPayments',
  async ({ page, type }, { dispatch }) => {
    try {
      const { data }: any = await api.V1PaymentsApi.paymentsControllerGetManyPayments(10, page, type);

      dispatch(actions.addUserPayments(data));
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

interface ContentChangeRequestData {
  aboutSelf?: string;
  avatarUrl?: string;
}

export const createContentChangeRequest = createAsyncThunk<void, ContentChangeRequestData, { state: RootState }>(
  'profile/fetchContentChangeRequest',
  async ({ aboutSelf, avatarUrl }, { dispatch, getState }) => {
    try {
      const contentChangeRequest = selectors.selectLastContentChangeRequest(getState());

      if (isEmpty(contentChangeRequest)) {
        const { data } =
          await api.V1ContentChangeRequestApi.createOneBaseContentChangeRequestsControllerContentChangeRequest({
            aboutSelf,
            avatarUrl,
          });
        dispatch(actions.setContentChangeRequests([data]));

        return;
      }

      const newContentChangeRequest = {
        ...contentChangeRequest,
        avatarUrl,
        aboutSelf,
      };

      const { data } =
        await api.V1ContentChangeRequestApi.updateOneBaseContentChangeRequestsControllerContentChangeRequest(
          contentChangeRequest.id,
          {
            avatarUrl: newContentChangeRequest.avatarUrl,
            aboutSelf: newContentChangeRequest.aboutSelf,
          },
        );

      dispatch(actions.setContentChangeRequests([data]));
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchContentChangeRequests = createAsyncThunk<ContentChangeRequest[], undefined>(
  'profile/fetchContentChangeRequest',
  async (fields, { rejectWithValue }) => {
    try {
      const { data } =
        await api.V1ContentChangeRequestApi.getManyBaseContentChangeRequestsControllerContentChangeRequest();

      // dispatch(actions.addUserPromocodes(promocodes));

      return data;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
      return rejectWithValue(e);
    }
  },
);

export const signOut = createAsyncThunk('profile/signOut', (_, { dispatch }) => {
  dispatch(rootActions.chatMessages.resetState());
  dispatch(rootActions.chatRooms.resetState());
  dispatch(rootActions.consultationRequests.resetState());
  dispatch(rootActions.consultationsChatRoom.resetState());
  dispatch(rootActions.profile.resetStore());
  dispatch(rootActions.channelNews.resetState());
  return true;
});

export const fetchSignInSms = createAsyncThunk<User | undefined, SigninDto>(
  'user/signInSms',
  async (dto, { dispatch }) => {
    try {
      const { data: userData } = await api.V1UsersApi.usersControllerSignInSms(dto);

      setCookie(COOKIE_KEY.accessToken, userData.token);

      dispatch(actions.authUser(userData));

      return userData.user;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);
export const fetchSelfPromocode = createAsyncThunk<boolean, undefined, ExtraParamsThunkType<SerializedError>>(
  'user/fetchSelfPromocode',
  async (_, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data: promocodes } = await api.V1PromocodesApi.promocodesControllerGetSelfPromocodes();

      dispatch(actions.addUserPromocodes(promocodes));

      return true;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
      return rejectWithValue(e);
    }
  },
);

export const fetchUpdateIam = createAsyncThunk<boolean | undefined, UpdateUserDto>(
  'user/updateIam',
  async (dto, { dispatch }) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerUpdateIAM(dto);
      dispatch(actions.updateUser(data));
      return true;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchUpdateIamProfile = createAsyncThunk<User | undefined, UpdateUserProfileDto>(
  'user/updateIam',
  async (dto, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerUpdateIAMProfile(dto);
      dispatch(actions.updateUser(data));
      return data;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
      rejectWithValue(e);
    }
  },
);

export const updateUserSetExpert = createAsyncThunk<boolean | undefined>(
  'user/updateIsExpert',
  async (_, { dispatch }) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerUpdateIsExpert();
      dispatch(actions.updateUser({ isExpert: data }));
      return true;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchAddUserToCategory = createAsyncThunk<
  UserCategoryMeta,
  CreateUserCategoryMetaDto,
  ExtraParamsThunkType<SerializedError>
>('user/addUserToCategory', async (dto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1UserCategoriesApi.userCategoriesMetaControllerCreateOne(dto);
    return data;
  } catch (e: any) {
    captureError(e);
    return rejectWithValue(e);
  }
});

export const fetchUpdatePriceCategory = createAsyncThunk(
  'user/updatePriceCategory',
  async ({ category, dto }: { category: UserCategoryMeta; dto: UpdateUserCategoryMetaDto }, { dispatch }) => {
    try {
      const { data } = await api.V1UserCategoriesApi.userCategoriesMetaControllerUpdateOne(category.id, dto);
      dispatch(actions.updateUserCategories([{ ...category, ...data }]));
      return true;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchDeleteUserFromCategory = createAsyncThunk<boolean | undefined, number>(
  'user/updatePriceCategory',
  async (id, { dispatch }) => {
    try {
      await api.V1UserCategoriesApi.userCategoriesMetaControllerRemoveOne(id);
      dispatch(actions.deleteUserCategory(id));
      return true;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchGetNotificationSettings = createAsyncThunk<NotificationsSetting | undefined>(
  'user/getNotificationSettings',
  async (_, { dispatch }) => {
    try {
      const { data } = await api.V1NotificationsSettingsApi.notificationsSettingsControllerGetSettings();
      dispatch(actions.updateNotificationSetting(data));
      return data;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchUpdateNotificationSettings = createAsyncThunk<
  NotificationsSetting | undefined,
  UpdateNotificationsSettingDto
>('user/getNotificationSettings', async (dto, { dispatch }) => {
  try {
    const { data } = await api.V1NotificationsSettingsApi.notificationsSettingsControllerUpdateSettings(dto);
    dispatch(actions.updateNotificationSetting(data));
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
  }
});

export const sendIndividualContract = createAsyncThunk<void, CreateIndividualContractDto>(
  'user/sendIndividualContract',
  async (payload, { dispatch }) => {
    try {
      const { data } = await api.V1ContractsApi.contractsControllerCreateOneIndividual(payload);
      dispatch(actions.setUserContract(data));
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const sendSelfEmployedContract = createAsyncThunk<void, CreateSelfEmployedContractDto>(
  'user/sendSelfEmployedContract',
  async (payload, { dispatch }) => {
    try {
      const { data } = await api.V1ContractsApi.contractsControllerCreateOneSelfEmployed(payload);
      dispatch(actions.setUserContract(data));
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchSelfContract = createAsyncThunk('user/fetchSelfContract', async (payload, { dispatch }) => {
  try {
    const { data } = await api.V1ContractsApi.contractsControllerGetSelf();
    dispatch(actions.setUserContract(data));
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
  }
});

export const fetchSelfPaymentMethods = createAsyncThunk('user/fetchSelfPaymentMethods', async (_, { dispatch }) => {
  try {
    const { data } = await api.V1PaymentMethodsApi.paymentMethodsControllerGetUserPaymentMethods();
    dispatch(actions.updatePaymentMethods(data));

    return data;
  } catch (error: any) {
    captureError(error);
    handleAsyncError(error);
  }
});

export const updateDefaultPaymentMethod = createAsyncThunk<
  boolean,
  UpdateDefaultPaymentMethodDto,
  ExtraParamsThunkType<SerializedError>
>('user/updateDefaultPaymentMethod', async (dto, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await api.V1PaymentMethodsApi.paymentMethodsControllerUpdateDefaultPaymentMethod({
      paymentMethodId: dto.paymentMethodId,
    });
    await dispatch(fetchWallet());
    return data;
  } catch (error: any) {
    captureError(error);
    handleAsyncError(error);
    return rejectWithValue(error);
  }
});

export const fetchConsultationsWithoutReview = createAsyncThunk<
  Consultation[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('user/getConsultationsWithoutReview', async (_, { rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.consultationsControllerGetSelfWithoutReview();
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const removeConsultationWithReview = createAction<number>('user/deleteConsultationWithReview');

export const updateSelfEmployedContract = createAsyncThunk<
  Contract,
  { id: number; dto: UpdateSelfEmployedContractDto },
  ExtraParamsThunkType<SerializedError>
>('user/updateSelfEmployedContract', async ({ id, dto }, { extra: { api }, dispatch, rejectWithValue }) => {
  try {
    const { data } = await api.V1ContractsApi.contractsControllerUpdateOneSelfEmployed(id, dto);
    dispatch(actions.setUserContract(data));
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const fetchConsultations = createAsyncThunk<
  GetManyConsultationsResponseDto,
  { page: number; filter: ('asExpert' | 'asClient' | 'PAID')[]; join?: Array<'messages' | 'invoices' | 'receipts'> },
  ExtraParamsThunkType<SerializedError>
>('user/getConsultations', async ({ page, filter, join = ['invoices', 'receipts'] }, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.V1ConsultationsApi.consultationsControllerGetMany(10, page, join, filter);
    dispatch(actions.addUserConsultations(data));
    return data;
  } catch (e: any) {
    captureError(e);
    handleAsyncError(e);
    return rejectWithValue(e);
  }
});

export const sendSmsAsync = createAsyncThunk<string, SmsCodeDto, ExtraParamsThunkType<SerializedError>>(
  'user/send-sms',
  async (dto, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerSendSms(dto);
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);
export const sendZoomCodeAsync = createAsyncThunk<User, ExpertZoomCodeDto, ExtraParamsThunkType<SerializedError>>(
  'user/send-code',
  async (dto, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerSaveZoomToken(dto);
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const deleteRefreshZoomTokenAsync = createAsyncThunk<void, void, ExtraParamsThunkType<SerializedError>>(
  'user/send-code',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.V1UsersApi.usersControllerDeleteZoomToken();
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const applyPromocodeAsync = createAsyncThunk<boolean, ApplyPromocodeDto, ExtraParamsThunkType<SerializedError>>(
  'user/apply-promocode',
  async (dto, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1PromocodesApi.promocodesControllerApplyPromocode(dto);
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const addPortfolioImageUrlsAsync = createAsyncThunk<
  Portfolio,
  { id: number; dto: AddImageUrlsDto },
  ExtraParamsThunkType<SerializedError>
>('profile/add-portfolio-images-urls', async ({ id, dto }, { rejectWithValue }) => {
  try {
    const { data } = await api.V1PortfoliosApi.portfoliosControllerAddImageUrls(id, dto);

    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const sendVerificationEmailAsync = createAsyncThunk<User, undefined, ExtraParamsThunkType<SerializedError>>(
  'profile/send-verification-email',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerSendVerificationEmail();
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const createOneCustomRequestAsync = createAsyncThunk<
  CustomRequest,
  CreateCustomRequestDto,
  ExtraParamsThunkType<SerializedError>
>('profile/create-one-custom-request', async (dto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1CustomRequestsApi.createOneBaseCustomRequestsControllerCustomRequest(dto);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const updateOneCustomRequestAsync = createAsyncThunk<
  CustomRequest,
  { id: number; dto: UpdateCustomRequestDto },
  ExtraParamsThunkType<SerializedError>
>('profile/update-one-custom-request', async ({ id, dto }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1CustomRequestsApi.updateOneBaseCustomRequestsControllerCustomRequest(id, dto);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const getManyReviewsAsync = createAsyncThunk<
  GetManyReviewResponseDto,
  Params | undefined,
  ExtraParamsThunkType<SerializedError>
>('profile/get-many-reviews', async (params, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ReviewsApi.getManyBaseReviewsControllerReview(
      params?.fields,
      params?.s,
      params?.filter,
      params?.or,
      params?.sort,
      params?.join,
      params?.limit,
      params?.offset,
      params?.page,
      params?.cache,
      params?.options,
    );
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const addUserStepAsync = createAsyncThunk<UserStepsEnum, ExpertStepDto, ExtraParamsThunkType<SerializedError>>(
  'profile/add-user-step',
  async (dto, { extra: { api }, rejectWithValue }) => {
    try {
      await api.V1UsersApi.usersControllerAddStep(dto);
      return dto.step as unknown as UserStepsEnum;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);
