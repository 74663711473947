const path = require('path');

module.exports = {
  debug: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development',
  i18n: {
    defaultLocale: 'ru',
    locales: ['ru', 'en'],
  },

  localePath: path.resolve('./public/locales'),
  react: {
    useSuspense: false,
  },
};
