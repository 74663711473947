export enum routes {
  home = '/',
  expertLanding = '/expert',
  topics = '/app/topics',
  mobile = '/app/mobile',
  category = '/app/topics/:id',
  experts = '/app/experts',
  expert = '/app/expert/:id',
  profile = '/app/profile',
  profileSteps = '/app/profile/steps',
  chat = '/app/chat',
  contract = '/app/contract',
  contractCorrect = '/app/contract/correct',
  academyExperts = '/app/academy',
  rules = '/app/rules',
  contacts = '/app/contacts',
  about = '/about',
  stopInfoBis = '/stopinfobis',
  responses = '/app/chat?activeTab=responses',
  oferta = '/oferta',
  policy = '/policy',
  policyweb = '/policyweb',
  trade = '/trade',
  faq = '/faq',
  sms = '/sms',
  blog = '/blog',
  blogtwo = '/blogtwo',
  booking = '/app/booking-zoom-meeting',
  bookingSuccessfully = '/app/booking-zoom-successfully',
  zoomIntegrationInstructions = 'zoom-integration-instructions',
}
